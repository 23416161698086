<template>
  <header
    class="PageHeader header sticky top-0 w-full z-header"
  >
    <!-- * * * * * * * mobile -->
    <div
      class="desk:hidden h-[52px]
    bg-lightest shadow-bottomLine
    flex justify-between items-center"
    >
      <div class="basis-1/2 pl-12">
        <button
          class="w-20 h-20 flex items-center"
          :title="$t('header.menu.open')"
          @click="openMenu"
        >
          <img
            src="/icons/menu.svg"
            class="w-20 h-20"
            :alt="$t('header.menu')"
          >
        </button>
      </div>
      <nuxt-link :to="manualUrl('/')" class="block basis-[75px] shrink-0">
        <img
          :src="`/logos/${globalContent.getLogoUrl}.svg`"
          class="w-[75px]"
          alt="logo"
        >
      </nuxt-link>
      <div class="basis-1/2 pr-12 flex justify-end items-center">
        <div class="relative">
          <button
            class="flex items-center type-headline-xs leading-single pl-12"
            @click="myPage()"
          >
            <img
              :src="isSeller ? '/icons/laptop-performance.svg' : '/icons/mypage.svg'"
              class="w-20 h-20 inline-block"
              :alt="isSeller ? $t('header.sellerTools') : $t('header.myPage')"
            >
          </button>
          <div v-if="showDropdown" class="absolute right-0 top-28 z-modals">
            <AccountDropdown @close="showDropdown = false" />
          </div>
        </div>
        <nuxt-link
          v-if="isSeller && userProfile.impersonatingCompany"
          class="flex items-center type-headline-xs leading-single pl-12"
          :to="accountUrl('dashboard')"
        >
          <div class="flex">
            <div class="bg-brightGreen w-24 h-24 border-2 border-lightest flex items-center justify-center rounded-full">
              <img
                src="/icons/smiley.svg"
                class="w-16 h-16 inline-block"
              >
            </div>
            <div class="bg-brightGreen w-24 h-24 border-2 border-lightest flex items-center justify-center rounded-full -ml-8">
              <img
                src="/icons/smiley.svg"
                class="w-16 h-16 inline-block"
              >
            </div>
          </div>
        </nuxt-link>
        <button
          v-else-if="isSeller && !userProfile.impersonatingCompany"
          class="flex items-center pl-12 type-headline-xs leading-single"
          @click="onImpersonate"
        >
          <img
            src="/icons/glasses.svg"
            class="w-20 h-20 inline-block"
            :alt="$t('header.impersonate')"
          >
        </button>
        <nuxt-link
          v-if="!isSeller && userStore.isLoggedIn"
          class="pl-12"
          :to="accountUrl('favorites')"
        >
          <img
            src="/icons/favorite.svg"
            class="w-20 h-20 inline-block"
            :alt="$t('header.favorites')"
          >
        </nuxt-link>
        <CartIcon />
      </div>
    </div>

    <!-- * * * * * * * desktop -->
    <div
      class="hidden desk:block
             h-[53px] w-full
             bg-lightest shadow-bottomLine
            relative z-modalsContent"
    >
      <div class="flex layout-container items-center justify-between h-[53px]">
        <div class="basis-1/2 flex items-center text-16">
          <label for="desktopSearchInput">
            <img
              src="/icons/search.svg"
              class="w-20 h-20 mr-12"
              :alt="$t('search.placeholder')"
            >
          </label>
          <input
            id="desktopSearchInput"
            ref="desktopSearchInput"
            v-model="search"
            type="text"
            :placeholder="$t('search.placeholder')"
            class="w-full placeholder-opacity-100 pt-4"
            @focus="showDesktopSearch"
            @keyup.esc="closeDesktopSearch"
            @keyup.enter="goToSearchPage"
          >
        </div>
        <nuxt-link
          v-if="!uiStore.showDesktopSearch"
          :to="manualUrl('/')"
          class="block basis-[93px] shrink-0"
        >
          <img
            :src="`/logos/${globalContent.getLogoUrl}.svg`"
            class="w-[93px] h-[19px]"
            alt="logo"
          >
        </nuxt-link>
        <div
          v-if="!uiStore.showDesktopSearch"
          class="basis-1/2 flex justify-end items-center type-headline-xs"
        >
          <div class="relative">
            <button
              class="flex items-center ml-20 leading-single type-headline-xs"
              @click="myPage()"
            >
              <img
                :src="isSeller ? '/icons/laptop-performance.svg' : '/icons/mypage.svg'"
                class="w-20 h-20 mr-12 inline-block"
                :alt="isSeller ? $t('header.sellerTools') : $t('header.myPage')"
              >
              <div class="fixBaseline  whitespace-nowrap overflow-hidden overflow-ellipsis">{{ myPagesText }}</div>
            </button>
          </div>

          <button
            v-if="isSeller && !userProfile.impersonatingCompany"
            class="flex items-center ml-20 leading-single"
            @click="onImpersonate"
          >
            <img
              src="/icons/glasses.svg"
              class="w-20 h-20 mr-12 inline-block"
              :alt="$t('header.impersonate')"
            >
            <div class="fixBaseline">{{ $t('header.impersonate') }}</div>
          </button>
          <button
            v-if="isSeller && userProfile.impersonatingCompany"
            class="flex items-center ml-20 leading-single"
            @click="myPage()"
          >
            <div class="flex mr-4">
              <div class="bg-brightGreen w-24 h-24 border-2 border-lightest flex items-center justify-center rounded-full">
                <img
                  src="/icons/smiley.svg"
                  class="w-16 h-16 inline-block"
                >
              </div>
              <div class="bg-brightGreen w-24 h-24 border-2 border-lightest flex items-center justify-center rounded-full -ml-8">
                <img
                  src="/icons/smiley.svg"
                  class="w-16 h-16 inline-block"
                >
              </div>
            </div>
            <div class="fixBaseline">{{ userProfile.company.name }}</div>
          </button>
          <!--          <button
            v-else-if="isCustomerSuccess"
            class="flex items-center ml-20  leading-single"
            @click="logOut"
          >
            <img
              src="/mypage/exit.svg"
              class="w-20 h-20 mr-12 inline-block"
            >
            <div class="fixBaseline">
              {{ $t('mypage.logout') }}
            </div>
          </button>-->
          <!--          <nuxt-link
            v-else-if="userStore.isLoggedIn && !isSeller"
            class="flex items-center ml-20 type-headline-xs leading-single"
            :to="accountUrl('favorites')"
          >
            <img
              src="/icons/favorite.svg"
              class="w-20 h-20 mr-12 inline-block"
              :alt="$t('header.favorites')"
            >
            <div class="fixBaseline hidden deskStd:flex">{{ $t('header.favorites') }}</div>
          </nuxt-link>-->

          <div class="relative">
            <div v-if="showDropdown" class="absolute right-0 top-28 z-modals">
              <AccountDropdown @close="showDropdown = false" />
            </div>
          </div>

          <nuxt-link
            v-if="userStore.isCustomerSuccessUser && userProfile.impersonatingCompany"
            class="flex items-center ml-20 type-headline-xs leading-single"
            :to="accountUrl('favorites')"
          >
            <img
              src="/icons/favorite.svg"
              class="w-20 h-20 mr-12 inline-block"
              :alt="$t('header.favorites')"
            >
            <div class="fixBaseline hidden deskStd:flex">{{ $t('header.favorites') }}</div>
          </nuxt-link>

          <CartIcon />
        </div>
        <button
          v-if="uiStore.showDesktopSearch"
          class="flex items-center"
          @click="closeDesktopSearch"
        >
          <div class="mr-12 type-headline-xs fixBaseline">{{ $t('search.cancel') }}</div>
          <img
            src="/icons/close.svg"
            class="w-24 h-24"
            :alt="$t('search.cancel')"
          >
        </button>
      </div>
      <transition name="fade">
        <div v-show="uiStore.showDesktopSearch" class="relative">
          <div class="absolute top-0 left-0 w-full bg-lightest border-t border-light searchResultHeight">
            <SearchResult
              v-if="uiStore.searchOpenedOnceDesk"
              :search="search"
              @select="(e) => search = e"
              @go-to-search-page="goToSearchPage"
            />
          </div>
        </div>
      </transition>
    </div>

    <DesktopMenu
      v-if="!uiStore.isMobile"
    />
  </header>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import CartIcon from '~/components/cart/CartIcon.vue';
import useLinkReplacer from '~/composeables/useLinkReplacer';
import { useGlobalContentStore } from '~/store/globalContent';
import { useUserStore } from '~/store/user';
import { storeToRefs } from 'pinia';
import SearchResult from '~/components/search/SearchResult.vue';
import AccountDropdown from '~/components/menu/AccountDropdown.vue';
import DesktopMenu from '~/components/menu/DesktopMenu.vue';

const { accountUrl, manualUrl } = useLinkReplacer();

const { $t } = useNuxtApp();
const uiStore = useUiStore();
const globalContent = useGlobalContentStore();
const userStore = useUserStore();

const { userProfile } = storeToRefs(userStore);

const openMenu = () => {
  uiStore.sideMenuParents = [];
  uiStore.setShowSideMenu(true);
};

const showDropdown = ref(false);
const myPage = () => {

  if (isCustomerSuccess.value && !userStore.userProfile.impersonatingCompany) {
    uiStore.setShowLogInAsCustomer(true);
  } else if (userStore.loggedIn) {
    if (!showDropdown.value) {
      showDropdown.value = true;
    }
  } else {
    uiStore.setShowSigninModal(true);
  }
};

const onImpersonate = () => {
  uiStore.setImpersonateModal(true);
};

const search = ref('');

const desktopSearchInput = ref<HTMLElement>();
const closeDesktopSearch = () => {
  search.value = '';
  uiStore.showDesktopSearch = false;
  desktopSearchInput.value?.blur();
};

const goToSearchPage = () => {
  uiStore.showDesktopSearch = false;
  desktopSearchInput.value?.blur();
  navigateTo(manualUrl(`/search?q=${search.value}`));
  search.value = '';
};

const isSeller = computed(() => {
  return userStore.isSalesRepUser;
});

const isCustomerSuccess = computed(() => {
  return userStore.isCustomerSuccessUser;
});

const myPagesText = computed(() => {
  if (isSeller.value) {
    return $t('header.sellerTools');
  } else if (isCustomerSuccess.value) {
    return userStore.userProfile.impersonatingCompany ? userStore.userProfile.company.name : $t('header.customerSuccessImpersonate');
  } else if (userStore.isLoggedIn) {
    return userStore.userProfile.company.name;
  } else {
    return $t('header.myPage');
  }
});

const logOut = async() => {
  await userStore.logOut();
};

const showDesktopSearch = () => {
  uiStore.searchOpenedOnceDesk = true;
  uiStore.showDesktopSearch = true;
};

</script>

<style scoped lang="postcss">
.searchResultHeight {
  max-height: calc(100vh - 53px); /* entire screen - header */
  height: calc(100vh - 53px);
  @apply overflow-auto pb-64; /* padding bottom, at least 32 for if sitemessages are hidden */
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
